<template>
  <div>
    <section class="section">
      <div class="section-center">
        <h3 class="title">
          <img
            src="../../assets/image/enterpriseNews/stafftraining/imgtitlebg1.png"
            alt=""
          />
        </h3>
        <el-carousel :interval="6000" type="card">
          <el-carousel-item>
            <img
              src="../../assets/image/enterpriseNews/stafftraining/img1.png"
              alt=""
            />
          </el-carousel-item>
          <el-carousel-item>
            <img
              src="../../assets/image/enterpriseNews/stafftraining/img2.png"
              alt=""
            />
          </el-carousel-item>
          <el-carousel-item>
            <img
              src="../../assets/image/enterpriseNews/stafftraining/img3.png"
              alt=""
            />
          </el-carousel-item>
        </el-carousel>
        <h3 class="title">
          <img
            src="../../assets/image/enterpriseNews/stafftraining/imgtitlebg2.png"
            alt=""
          />
        </h3>
        <el-carousel :interval="6000" type="card">
          <el-carousel-item>
            <img
              src="../../assets/image/enterpriseNews/stafftraining/img4.png"
              alt=""
            />
          </el-carousel-item>
          <el-carousel-item>
            <img
              src="../../assets/image/enterpriseNews/stafftraining/img5.png"
              alt=""
            />
          </el-carousel-item>
          <el-carousel-item>
            <img
              src="../../assets/image/enterpriseNews/stafftraining/img6.png"
              alt=""
            />
          </el-carousel-item>
        </el-carousel>
      </div>
    </section>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
.section {
  width: 100%;
  .section-center {
    font-size: 18px;
    color: #000;
    max-width: 1466px;
    margin: 0 auto;
    .title {
      height: 62px;
      margin: 60px 0 50px 0px;
    }
  }
}
</style>